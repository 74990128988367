const moduleName = 'dropdown';

export function dropdown() {

    Array.from(document.querySelectorAll('.js-dropdown')).forEach((self) => {
        if (self.dataset[moduleName + 'Init'] === 'true') return;
        self.dataset[moduleName + 'Init'] = true;

        const BREAKPOINT = 1024;
        const isMobile = document.body.classList.contains('mobile');

        const holder = self;
        const toggle = holder.querySelector('.js-dropdown-toggle');

        holder.addEventListener('mouseenter', () => {
            if (isMobile || document.body.clientWidth <= BREAKPOINT) return;

            showMenu();
        });

        holder.addEventListener('mouseleave', () => {
            if (isMobile || document.body.clientWidth <= BREAKPOINT) return;

            hideMenu();
        });

        toggle.addEventListener('click', (event) => {
            if (!isMobile && document.body.clientWidth > BREAKPOINT) return;

            event.preventDefault();

            if (holder.classList.contains('_open')) {
                hideMenu();
            } else {
                showMenu();
            }
        });



        function showMenu() {
            holder.classList.add('_open');
        }

        function hideMenu() {
            holder.classList.remove('_open');
        }
    });

}