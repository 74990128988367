import Swiper from 'swiper';

const moduleName = 'partners';

export function partners() {

    Array.from(document.querySelectorAll('.js-partners')).forEach((self) => {
        if (self.dataset[moduleName + 'Init'] === 'true') return;
        self.dataset[moduleName + 'Init'] = true;

        const partners = self;
        const partnersSlider = partners.querySelector('.js-partners-slider');
        const partnersPrevButton = partners.querySelector('.js-partners-prev-button');
        const partnersNextButton = partners.querySelector('.js-partners-next-button');
        const partnersSwiper = new Swiper(partnersSlider, {
            slidesPerView: 3,
            simulateTouch: false,
            navigation: {
                prevEl: partnersPrevButton,
                nextEl: partnersNextButton,
                disabledClass: '_disabled'
            },
            breakpoints: {
                1023: {
                    slidesPerView: 2
                },
                399: {
                    slidesPerView: 1
                }
            }
        });
    });

}