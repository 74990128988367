// polyfills
import 'core-js/fn/array/from';
import './modules/polyfills';

// detect mobile devices
import './modules/mobileDetect';

// jQuery
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

// modules
import { overlay } from './modules/overlay';
import { scrollTo } from './modules/scrollTo';
import { popup } from './modules/popup';
import { partners } from './modules/partners';
import { dropdown } from './modules/dropdown';
import { nav } from './modules/nav';

window.overlay = overlay;
window.initModules = () => {
    scrollTo();
    popup();
    partners();
    dropdown();
    nav();
};

// init modules on page load
if (document.readyState === 'complete') {
    if (!document.body.classList.contains('preload')) {
        initModules();
    } else {
        preload(initModules);
    }
} else if (document.readyState === 'interactive' && !document.body.classList.contains('preload')) {
    initModules();
} else {
    document.addEventListener('DOMContentLoaded', () => {
        if (!document.body.classList.contains('preload')) {
            initModules();
        }
    });

    window.addEventListener('load', () => {
        if (document.body.classList.contains('preload')) {
            preload(initModules);
        }
    });
}