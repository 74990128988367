const moduleName = 'nav';

export function nav() {

    Array.from(document.querySelectorAll('.js-nav')).forEach((self) => {
        if (self.dataset[moduleName + 'Init'] === 'true') return;
        self.dataset[moduleName + 'Init'] = true;

        const nav = self;
        const navOpenButton = nav.querySelector('.js-nav-open-button');
        const navCloseButton = nav.querySelector('.js-nav-close-button');

        navOpenButton.addEventListener('click', showNav);
        navCloseButton.addEventListener('click', hideNav);

        document.addEventListener('click', (event) => {
            if (!event.target.closest('.js-nav') && nav.classList.contains('_open')) hideNav();
        });

        window.addEventListener('keydown', (event) => {
            if (event.keyCode === 27 && nav.classList.contains('_open')) hideNav();
        });



        function showNav() {
            nav.classList.add('_open');
            overlay().show();
        }

        function hideNav() {
            nav.classList.remove('_open');
            overlay().hide();
        }
    });

}